import React from "react";
import StyledTextSection from "./styled";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import father_daughter from "./images/father-daughter.png";
import card_icon from "./images/card-icon.svg";
import house_icon from "./images/house-icon.svg";
import money_icon from "./images/money-icon.svg";
import money2_icon from "./images/money2-icon.svg";
import percent_icon from "./images/percent-icon.svg";
import person_icon from "./images/person-icon.svg";
import repair_icon from "./images/repair-icon.svg";
const TextSection = () => {
  return (
    <StyledTextSection>
      <Container className=" boost">
        <Row>
          <Col>
            <h3>Boost Your Financial Perspective</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              As an Originator who appreciates the service and sacrifices made
              by our military personnel, we have great news for our veterans!
              Introducing the exclusive 100% Loan-to-Value VA Cash-Out Loan
              program, tailor-made to help you, our honored veteran, achieve
              your financial goals by tapping into your home's equity.
            </p>
            <p>
              Experience the freedom to customize your loan terms with this
              innovative program. Whether you want to access home equity or
              transform a non-VA loan into a VA-backed loan, the VA cash-out
              refinance loan empowers you to achieve your goals.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className="ready-to-use">
        <div className="blue-bg"></div>
        <Container>
          <Row className="d-flex flex-column-reverse flex-xl-row justify-content-center align-items-center">
            <Col xl={9}>
              <h3>Tap into Your Home Equity</h3>
              <div className="textbox">
                <p>
                  With the 100% LTV VA Cash-Out Loan, you can access up to 100%
                  of your home’s appraised value and convert it into cold, hard
                  cash. It’s a way to turn your home equity into money by
                  getting a new loan for more than your current mortgage
                  balance. The difference between the two is the “cash-out”
                  amount.
                </p>
                <p>
                  With rising interest rates and increasing home prices, many
                  homeowners find themselves with more home equity without any
                  effort. This equity can be used as a savings account that you
                  can tap into when needed.
                </p>
              </div>
            </Col>
            <Col
              className="d-flex justify-content-center align-items-center"
              xl={3}
            >
              <Image className="father-daughter" src={father_daughter} />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="eligibility">
        <Container>
          <Row className="px-0">
            <Col>
              <h6>Eligibility</h6>
            </Col>
            <Col>
              <div className="el-box">
                <div className="icon-box">
                  <Image fluid className="el-icon" src={house_icon} />
                </div>
                <p className="el-text">Primary Residence</p>
              </div>
            </Col>
            <Col>
              <div className="el-box">
                <div className="icon-box">
                  <Image fluid className="el-icon" src={money_icon} />
                </div>
                <p className="el-text">Minimum FICO: 620</p>
              </div>
            </Col>
            <Col>
              <div className="el-box">
                <div className="icon-box">
                  <Image fluid className="el-icon" src={person_icon} />
                </div>
                <p className="el-text">No. of Units: 1-4</p>
              </div>
            </Col>
            <Col>
              <div className="el-box">
                <div className="icon-box">
                  <Image fluid className="el-icon" src={percent_icon} />
                </div>
                <p className="el-text">Maximum LTV: 100%*</p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="hidden-treasures">
        <Container>
          <Row className="d-flex justify-content-center">
            <h3 className="d-flex justify-content-center">
              Unlock Your Hidden Treasure
            </h3>
          </Row>
          <Row className="d-flex flex-column flex-xl-row  justify-content-between justify-content-xxl-center pt-5">
            <Col xl={4} xxl={3}>
              <div className="treasure-box">
                <Image className="treasure-icon" src={card_icon} />
                <h6 className="treasure-title">Consolidate Debts</h6>
                <ul className="treasure-list">
                  <li className="treasure-item">
                    Pay off your credit card debt, student loans, car loan or a
                    second mortgage
                  </li>
                  <li className="treasure-item">
                    Swap high cost debts with a lower cost mortgage
                  </li>
                </ul>
              </div>
            </Col>
            <Col xl={4} xxl={4}>
              <div className="treasure-box">
                <Image className="treasure-icon" src={repair_icon} />
                <h6 className="treasure-title">Repair or Renovate Your Home</h6>
                <ul className="treasure-list">
                  <li className="treasure-item">
                    Build a dream kitchen or a detached workshop
                  </li>
                  <li className="treasure-item">
                    Add a pool or screened-in porch
                  </li>
                  <li className="treasure-item">
                    Replace the roof of your home
                  </li>
                  <li className="treasure-item">
                    Repair the sidewalk/driveway
                  </li>
                  <li className="treasure-item">Landscaping</li>
                  <li className="treasure-item">
                    Site improvements, patios, decks, and terrace repairs
                  </li>
                </ul>
              </div>
            </Col>
            <Col xl={4} xxl={3}>
              <div className="treasure-box">
                <Image className="treasure-icon" src={money2_icon} />
                <h6 className="treasure-title">
                  {" "}
                  Meet Personal Expenses or
                  <br /> Invest Elsewhere
                </h6>
                <ul className="treasure-list">
                  <li className="treasure-item">
                    Buy a new car, pay tuition fees, go on a dream vacation or
                    meet urgent needs
                  </li>
                  <li className="treasure-item">
                    Fund your retirement, long-term savings plans, or other
                    investments
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </StyledTextSection>
  );
};
export default TextSection;
