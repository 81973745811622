/* eslint-disable react/jsx-no-target-blank */
import React, { Suspense } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ScrollAnimation from 'react-animate-on-scroll';
import { lazy } from '@loadable/component';
import styles from '../Body/body.module.css';

const Image = lazy(() => import('react-bootstrap/Image'));

export default function BrokerAndCorrespondents() {
  return (
    <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
      <Container fluid={true} style={{ marginBottom: '25px' }}>
        <Row
          className={styles.headerRow}
          style={{ justifyContent: 'center', width: '100%' }}
        >
          <div className={styles.header_border}> </div>
          <h1 className={styles.whysunwesth2} style={{ fontSize: '2rem' }}>
            Mortgage Brokers and Correspondents
          </h1>
        </Row>
        <Row className='justify-content-center'>
          <Col sm={10} md={6} style={{ marginTop: '10px' }}>
            <a
              href="https://www.sunwestmortgage.com/wholesale-lending"
              target="_blank"
              rel="noopener"
              style={{ textDecoration: 'none' }}
            >
              <div className={styles.homePageHorizontalCards}>
                <div className={styles.imgsec}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Image
                        fluid
                        className={styles.homepageImg}
                        height="auto"
                        alt="People in a meeting"
                        src="https://www.sunwestmortgage.com/swmc-images/WholesaleLending/WholeSaleLending-resized-min.jpg"
                      />
                  </Suspense>
                </div>
                <div
                  className={styles.contentsection}
                  style={{ marginLeft: '5%' }}
                >
                  <h5>Mortgage Brokers</h5>
                  <p>For our Brokers</p>

                  <Button
                    variant="primary"
                    type="submit"
                    style={{
                      marginLeft: '5px',
                      borderRadius: '20px',
                      marginBottom: '10px',
                      marginTop: '10px',
                    }}
                    className={styles.loanSearchButton}
                  >
                    Click Here
                  </Button>
                </div>
              </div>
            </a>
          </Col>
          <Col sm={10} md={6} style={{ marginTop: '10px' }}>
            <a
              href="https://www.sunwestmortgage.com/correspondent-lending"
              target="_blank"
              rel="noopener"
              style={{ textDecoration: 'none' }}
            >
              <div className={styles.homePageHorizontalCards}>
                <div className={styles.imgsec}>
                  <Suspense fallback={<div>Loading...</div>}>
                      <Image
                        fluid
                        className={styles.homepageImg}
                        height="auto"
                        alt="Tall buildings"
                        src="https://www.sunwestmortgage.com/swmc-images/CorrespondentLending/CorrespondentLending-resized-min.jpg"
                      />
                  </Suspense>
                </div>
                <div
                  className={styles.contentsection}
                  style={{ marginLeft: '5%' }}
                >
                  <h5>Mortgage Correspondents</h5>
                  <p>For our Correspondents</p>

                  <Button
                    variant="primary"
                    type="submit"
                    style={{
                      marginLeft: '5px',
                      borderRadius: '20px',
                      marginBottom: '10px',
                      marginTop: '10px',
                    }}
                    className={styles.loanSearchButton}
                  >
                    Click Here
                  </Button>
                </div>
              </div>
            </a>
          </Col>
        </Row>
      </Container>
    </ScrollAnimation>
  );
}
