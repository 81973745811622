/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Suspense } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ScrollAnimation from "react-animate-on-scroll";
import parse from "html-react-parser";
import Banner from "../HomepageBanner";
import Testimonials from "../Testimonials";
import CallUs from "../CallUs";
import WhySunWest from "../WhySunWest";
import Steps from "../Steps";
import LoanCalculators from "../LoanCalculators";
import Blog from "../Blogs";
import BrokerAndCorrespondents from "../BrokersAndCorrespondents";
import styles from "./body.module.css";
import TextSection from "../TextSection";

export function HomePage({ setShowModal, setLoanOfficerUid, setLoanPurpose }) {
  const [siteData, setSiteData] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   axios
  //     .get(
  //       process.env.REACT_APP_CDN_LINK + 'jsons/GenericWebsites/GenericWebsites.json',
  //       {
  //         headers: { "Content-Type": "application/json" },
  //       },
  //       { timeout: 5000 }
  //     )
  //     .then((response) => {
  //       setSiteData(response.data.filter(site => site.domain.toLowerCase() === window.location.hostname.replace('www.', ''))[0]);
  //     })
  //     .catch((error) => {
  //       console.error("Error: ", error);
  //     });

  // }, []);

  return (
    <article>
      {/* {siteData && <Helmet>
        <meta charSet="utf-8" />
        <title>{siteData.title}</title>
        <meta name="description" content={siteData.description} />
        <meta name="keywords" content={siteData.keywords} />
        <meta property="og:url" content={`https://${window.location.hostname}`} />
        <meta property="og:type" content="website" />
        <meta name="title" property="og:title" content={siteData.title} />
        <meta name="image" property="og:image" content='https://resources.swmc.com/swmc-images/Your-True-Home-Is-Always-Within.jpg' />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="600" />
        <meta name="description" property="og:description" content={siteData.description} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content={`https://${window.location.hostname}`} />
        <meta name="twitter:title" content={siteData.title} />
        <meta name="twitter:description" content={siteData.description} />
        <meta name="twitter:image" content="https://resources.swmc.com/swmc-images/Your-True-Home-Is-Always-Within.jpg" />
      </Helmet>} */}
      <div>
        <Banner
          setShowModal={setShowModal}
          setLoanOfficerUid={setLoanOfficerUid}
          setLoanPurpose={setLoanPurpose}
        />
        {/* {siteData && <Container fluid>
          <Row className='justify-content-center pt-3 mt-5'>
            <Col md={11}>
                <h2 className={styles.siteHeader}>{parse(siteData.heading)}</h2>
                <h4 className={styles.siteContents}>{parse(siteData.bodyContents)}</h4>
            </Col>
          </Row>
        </Container>} */}
        <TextSection />
        <WhySunWest />
        <CallUs />
        <Testimonials />
        <Steps />
        <Suspense fallback={<div>Loading...</div>}>
          <div className={styles.getStartedDiv}>
            <Row style={{ margin: "0px" }}>
              <div className={styles.applynowCardBlock}>
                <div className={styles.applynowCard}>
                  <ScrollAnimation animateIn="zoomIn">
                    <h1>Ready to get started?</h1>
                    <p>
                      We are here to create a better mortgage experience with
                      integrity and trust. Click below to begin your mortgage
                      journey!
                    </p>
                    <a
                      href="https://www.sunwestmortgage.com/apply"
                      target="_blank"
                      rel="noopener"
                      className={styles.applynowCardButton}
                    >
                      Apply Now
                    </a>
                  </ScrollAnimation>
                </div>
              </div>
            </Row>
          </div>
        </Suspense>
        <LoanCalculators />
        <Blog />
        <BrokerAndCorrespondents />
        <Container>
          <Row>
            <Col>
              <p className={styles.fineprint}>
                A VA Refinance is not allowed on a property that is owned free
                and clear
                <br /> *Must meet at least one VA Net Tangible Benefits Test
                requirements  
                <br /> SWMC is acting as a broker for this program.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </article>
  );
}

export default HomePage;
