import styled from "styled-components";

const StyledTextSection = styled.section`
  font-family: poppins;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  margin-top: 1rem;
  overflow: hidden;
  @media (min-width: 1600px) {
    &
      :where(
        .ready-to-use.container-fluid .container,
        .boost,
        .eligibility .container,
        .hidden-treasures
      ) {
      max-width: 2000px;
      padding: 0 3rem;
    }
    .ready-to-use {
      padding: 0 2rem 2rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .eligibility {
    background: linear-gradient(180deg, #00a1d8 0%, #083f88 100%);
    padding: 3rem 0;
    position: relative;
    margin-top: -6.5rem;
  }
  .eligibility .container,
  .hidden-treasures .container {
    max-width: 2000px;
  }
  .eligibility .row {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  & :where(.boost, .ready-to-use) p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 130%;
    text-align: justify;
    color: #212529;
  }
  & .ready-to-use {
    position: relative;
  }
  & .ready-to-use .container {
    margin: 3rem 0;
    position: relative;
  }

  & .blue-bg {
    background: rgba(0, 170, 228, 0.2);
    width: 110%;
    position: absolute;
    height: 70%;
    left: 0;
    z-index: 0;
  }
  & .blue-bg::before {
    content: "";
    background: rgba(0, 170, 228, 0.5);
    width: 3rem;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  & h3 {
    padding: 0.5rem 0 1rem 0;

    font-weight: 600;
    font-size: 50px;
    line-height: 63px;
    color: #212529;
    position: relative;
  }
  & h3::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 9rem;
    height: 5px;
    background-color: #00aae4;
  }
  .hidden-treasures h3 {
    width: fit-content;
  }
  & .father-daughter {
    position: relative;
    margin-left: 10rem;
    right: 0;
    height: 500px;
    margin-top: -3rem;
    z-index: 1;
  }
  .boost {
    margin-bottom: 2rem;
    margin-top: 3rem;
  }
  .textbox {
    padding-bottom: 3rem;
  }
  .el-box {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
  }
  .icon-box {
    display: flex;
    justify-content: center;
    width: 100px;
  }
  .icon-box img {
    padding: 0.5rem;
  }

  .el-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 29.6698px;
    line-height: 30px;

    text-align: center;
    color: #ffffff;
  }
  .eligibility h6 {
    background: #ffffff;
    border-radius: 0px 50px 50px 0px;
    margin-top: 2rem;
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 50px;
    /* identical to box height, or 111% */
    padding: 1rem 0;
    position: relative;
    color: #083f88;
    width: 110%;
    margin-left: -1rem;
  }
  .treasure-box {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;

    box-sizing: border-box;
    border: 1px solid #00aae4;
    border-radius: 15px;
    padding: 0 1rem;
  }
  .treasure-icon {
    height: calc(75px + 2rem);
    margin-top: -4rem;
    position: relative;
    padding: 1rem;
    background-color: white;
    width: fit-content;
  }
  .treasure-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 172.5%;
    /* or 41px */
    text-align: center;

    color: #212529;
  }
  .treasure-item {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150.5%;
    /* or 38px */

    color: #212529;
  }
  .hidden-treasures {
    margin: 3rem 0;
  }
  @media (max-width: 1600px) {
    & .father-daughter {
      height: 450px;
      margin-bottom: 2rem;
      margin-left: 8rem;
    }
    .ready-to-use {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .eligibility {
      margin-top: -6.5rem;
    }
    & .blue-bg {
      background: rgba(0, 170, 228, 0.2);
      width: 110%;
      position: absolute;
      height: 70%;
      left: 0;
      z-index: 0;
      margin-top: -3rem;
    }
    padding: 0;

    .el-text {
      font-size: 22px;
      font-height: 100%;
    }
  }

  @media (max-width: 1450px) {
    & .blue-bg {
      height: 75%;
      //   margin-top: -0.5rem;
    }
    & .father-daughter {
      height: 400px;
      margin-left: 3rem;
    }
    & .eligibility {
      margin-top: -5.25rem;
    }
  }
  @media (max-width: 1400px) {
    & .father-daughter {
      height: 380px;
      margin-left: 3rem;
    }
    & .eligibility {
      margin-top: -5.5rem;
    }
  }
  @media (max-width: 1300px) {
    & .father-daughter {
      height: 380px;
      margin-left: 3rem;
    }
    .eligibility h6 {
      font-size: 35px;
    }
  }
  @media (max-width: 1200px) {
    .hidden-treasures .row {
      justify-content: center;
      align-items: center;
    }
    .treasure-box {
      margin: 2rem 0;
      padding: 1rem 0;
    }

    .ready-to-use {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
    }

    .father-daughter {
      position: relative;
      margin-left: 0;
    }
    .blue-bg {
      background: rgba(0, 170, 228, 0.2);
      width: 110%;
      position: absolute;
      height: 65%;
      top: unset;
      left: unset;
      right: unset;
      bottom: 0;
      margin-left: -3rem;
    }
    & :where(.ready-to-use, .boost) p {
      line-height: 170%;
    }
  }
  @media (max-width: 992px) {
    .blue-bg {
      height: 70%;
    }
    .eligibility h6 {
      font-size: 25px;
      margin-top: 3rem;
    }
    h3 {
      font-size: 35px;
      line-height: 40px;
    }
    .ready-to-use .container {
      margin: 2rem 0;
    }
    .eligibility {
      margin-top: -2rem;
    }
  }
  @media (max-width: 700px) {
    .eligibility {
      padding: 1rem 0;
      background: linear-gradient(180deg, #00a1d8 -20.33%, #083f88 69.17%);
      border-radius: 50px;
    }
    .eligibility h6 {
      width: 100%;
      margin: 0;
      border-radius: 50px;
    }
    .eligibility .row {
      flex-flow: column nowrap;
    }
    .el-box {
      margin: 1rem 0;
    }
  }
  @media (max-width: 600px) {
    .blue-bg {
      height: 75%;
    }
  }
  @media (max-width: 400px) {
    .blue-bg {
      height: 80%;
    }
    & .father-daughter {
      height: auto;
      width: 100%;
    }
    & h3 {
      font-size: 30px;
      font-weight: 700;
      line-height: 35px;
    }
  }
  @media (max-width: 350px) {
    .blue-bg {
      height: 85%;
    }
  }
`;
export default StyledTextSection;
