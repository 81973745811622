/* eslint-disable react/jsx-no-target-blank */
import React, { Suspense } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ScrollAnimation from 'react-animate-on-scroll';
import { lazy } from '@loadable/component';
import Wallet from '../../Images/wallet.png';
import CurrencyIcon from '../../Images/manifyandmorney.png';
import styles from '../Body/body.module.css';

const Image = lazy(() => import('react-bootstrap/Image'));

export default function LoanCalculators() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Container
          className={styles.calculatorContainer}
          style={{ paddingTop: '40px', maxWidth: '1140px' }}
        >
          <Row style={{ margin: '0px' }}>
            <Col sm={6} xs={12} md={6}>
              <div className={styles.calculatorContent}>
                <div className={styles.header_border}> </div>
                <h2>Loan Calculators</h2>
                <p>
                  No more wondering. Try our easy-to-use calculators to know
                  what will work best for you.{' '}
                </p>
                <a
                  href="https://www.sunwestmortgage.com/loan-calculators-mortgage-calculators"
                  target="_blank"
                  rel="noopener"
                  style={{ textDecoration: 'none' }}
                >
                  View More
                </a>
              </div>
            </Col>
            <Col lg={6} md={6} xs={12} sm={6} xl={6}>
              <ScrollAnimation
                animateIn="zoomIn"
                style={{ margin: '0 auto', maxWidth: '400px' }}
              >
                <a
                  style={{ color: 'black', textDecoration: 'none' }}
                  href="https://www.sunwestmortgage.com/loan-calculators-mortgage-calculators/affordability-calculators"
                  target="_blank"
                  rel="noopener"
                >
                  <div
                    className={styles.loanCalculator}
                    style={{
                      minHeight: '100px',
                      padding: '3% 0',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      boxShadow: '7px 2px 21px 0px #ece5e5',
                      border: '1px solid rgba(0,0,0,.125)',
                      borderRadius: '5px',
                    }}
                  >
                    <Row className={styles.innerLoanBody}>
                      <Col md={2}>
                        <Image
                          style={{ width: '70px', padding: '5px' }}
                          alt="Wallet"
                          src={Wallet}
                        />
                      </Col>
                      <Col md={10}>
                        <h5 style={{ padding: '10px 0 0px 0px' }}>
                          How Much Can I Afford?
                        </h5>
                        <p
                          style={{
                            padding: '10px 0 0px 5px',
                            fontSize: '14px',
                          }}
                        >
                          Calculates your maximum mortgage loan amount based
                          upon the monthly home loan payment, interest rate, and
                          term that you enter.
                        </p>
                        <center>
                          <Button className={styles.loanDivButtons}>
                            Click Here
                          </Button>
                        </center>
                      </Col>
                    </Row>
                  </div>
                </a>
              </ScrollAnimation>

              <ScrollAnimation
                animateIn="zoomIn"
                style={{ margin: '0 auto', maxWidth: '400px' }}
              >
                <a
                  style={{ color: 'black', textDecoration: 'none' }}
                  href="https://www.sunwestmortgage.com/loan-calculators-mortgage-calculators/refinance-calculators"
                  target="_blank"
                  rel="noopener"
                >
                  <div
                    className={styles.loanCalculator}
                    style={{
                      minHeight: '100px',
                      padding: '3% 0',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      boxShadow: '7px 2px 21px 0px #ece5e5',
                      border: '1px solid rgba(0,0,0,.125)',
                      borderRadius: '5px',
                    }}
                  >
                    <Row className={styles.innerLoanBody}>
                      <Col md={2}>
                        <Suspense fallback={<div>Loading...</div>}>
                          <Image
                            style={{ width: '70px', padding: '5px' }}
                            alt="Manify and dollar currency icon"
                            src={CurrencyIcon}
                          />
                        </Suspense>
                      </Col>
                      <Col md={10}>
                        <h5 style={{ padding: '10px 0 0px 0px' }}>
                          Should I Refinance?
                        </h5>
                        <p
                          style={{
                            padding: '10px 15px 0px 5px',
                            fontSize: '14px',
                          }}
                        >
                          Just enter information about your current mortgage
                          loan and the new loan you're thinking of taking, and
                          we'll calculate your savings.
                        </p>
                        <center>
                          <Button className={styles.loanDivButtons}>
                            Click Here
                          </Button>
                        </center>
                      </Col>
                    </Row>
                  </div>
                </a>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </Suspense>
    </>
  );
}
