/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import { Nav, Button } from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Classes from './index.module.css';
import LeadFormModal from '../LeadFormModal';
import styled from 'styled-components';

const NavWrapper = styled.div`
.navbar-dark .navbar-nav .nav-link{
  color: white;
  z-index: 1200;
  font-weight: 600;
  white-space:nowrap
}
.navbar-expand-lg .navbar-nav .dropdown-menu{
  margin: 0px 0px 0 -30px !important;
}
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color:transparent;
  border-left: 4px solid #083F88;
  border-right: 4px solid #083F88;
  transition: all 0.1s linear;
  border-radius:2px;
}
#dropdown-item-button::after{
  content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAA8ElEQVQ4jWNgGAUowHLOVSHjmc+4cMkbz3zGZTnnqhCyGBOMYTD/vsDvP2w7mf5/26E99SoPNs2M/79s+vWHbZ/xzJsiGAaw/PpbycDAYPKf4b8tFzPbRmSXGM98xsX0/+tmBgZGZ0YGBn1GBuZSuD4Y4z/DhxoGBj5VRgbGwP8MDE5Ql3hxsAj+Y/z/ZdN/BkYniErGrR9+/q+D6WNEdeYZVob/fCsZGRgDoZL7IIYzOEHo/+sZGD+Fn003+Y3VAGyGIADj1g+//gffyVP9iSKKbgB2Q7BrxmkAqiFMbLg0EwTGM8+wGs88w0qyxqEFAFtSY2S3+RTSAAAAAElFTkSuQmCC");
  vertical-align: middle;
  border:none;
  margin-top:0px;
}
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
  border-color:white;
}
.btn-primary:focus {
  
  border-color: white;
}
.btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background: #fff;
  color: #0481b5;
  border:1px solid #fff;
  box-shadow:none;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus{
  background: #fff;
  color: #0481b5;
  border:1px solid #fff;
  box-shadow:none;
}
@media (max-width: 575px) {
  a.dropdown-item {
      padding-left: 30px !important;
  }
}
.channelsNav{
  .dropdown-submenu-container a.dropdown-submenu::after {
      position: absolute;
      right: 0;
      top: 0;
      
  }
  .dropdown-menu{
    .dropdown-toggle::after {
        content: ">";
        vertical-align: middle;
        border: none;
        margin-top: 4px;
        font-weight: 600;
        color: #dddd;
        transform: rotate(0) !important;
    }
    padding-right:15px !important;
  }
}
.homepageNav{
    z-index: 1200;
    box-shadow:none;
    font-size:13px !important;
}   
`;

const NavBar = (props) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [navbarStyle, setNavbarStyle] = useState('');
  const [logoImage, setLogoImage] = useState('');
  const [showLeadForm, setShowLeadForm] = useState(false);

  const handleShowLeadFormModal = () => setShowLeadForm(true);
  const handleCloseLeadFormModal = () => setShowLeadForm(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    if (window.innerWidth > 989) {
      setLogoImage(
        'https://www.sunwestmortgage.com/swmc-images/Homepage/New/SunWest41YearsofTrust-Blue.png'
      );
      setNavbarStyle(Classes.normalNav);
    } else {
      setLogoImage(
        'https://www.sunwestmortgage.com/swmc-images/Homepage/New/SunWest41YearsofTrust-White.png'
      );
      setNavbarStyle(Classes.homepageNav);
    }
  }, []);

  useEffect(() => {
    if (scrollPosition < window.innerHeight + 0.2 - window.innerHeight) {
      if (window.innerWidth > 989) {
        setLogoImage(
          'https://www.sunwestmortgage.com/swmc-images/Homepage/New/SunWest41YearsofTrust-Blue.png'
        );
        setNavbarStyle(Classes.homepageNav);
      } else {
        setLogoImage(
          'https://www.sunwestmortgage.com/swmc-images/Homepage/New/SunWest41YearsofTrust-White.png'
        );
        setNavbarStyle(Classes.normalNav);
      }
    } else {
      setNavbarStyle(Classes.normalNav);
      setLogoImage(
        'https://www.sunwestmortgage.com/swmc-images/Homepage/New/SunWest41YearsofTrust-White.png'
      );
    }
  }, [window.location.pathname, scrollPosition]);

  useEffect(() => {
    if (window.pageYOffset < window.innerHeight + 0.2 - window.innerHeight) {
      setScrollPosition(0);
      if (window.innerWidth > 989) {
        setNavbarStyle(Classes.homepageNav);
      } else {
        setNavbarStyle(Classes.normalNav);
      }
    }

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [props]);

  const style = {
    color: 'black !important',
    backgroundColor: 'transparent',
    border: 'none',
  };

  return (
    <NavWrapper>
      <Navbar
        collapseOnSelect
        expand="lg"
        fixed="top"
        variant="dark"
        bg="transparent"
        className={navbarStyle}
      >
        <Navbar.Brand>
          <a
            href="https://www.sunwestmortgage.com/"
            target="_blank"
            rel="noopener"
          >
            <img
              src={logoImage}
              className={Classes.main_logo}
              height="auto"
              alt="swmc-logo"
            />
          </a>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto" style={{ margin: 'auto' }} />
          <Nav>
            <NavDropdown
              title="About"
              id="collasible-nav-dropdown"
              className={Classes.dropdown}
            >
              <Nav.Link
                className="dropdown-item"
                rel="noopener"
                target="_blank"
                href="https://www.sunwestmortgage.com/our-history"
              >
                Our History
              </Nav.Link>
              <Nav.Link
                className="dropdown-item"
                rel="noopener"
                target="_blank"
                href="https://www.sunwestmortgage.com/why-sunwest"
                exact
              >
                Success Stories
              </Nav.Link>
              <Nav.Link
                className="dropdown-item"
                rel="noopener"
                target="_blank"
                href="https://www.sunwestmortgage.com/careers"
              >
                Careers
              </Nav.Link>
              <Nav.Link
                className="dropdown-item"
                rel="noopener"
                target="_blank"
                href="https://www.sunwestmortgage.com/contact-us"
              >
                Contact Us
              </Nav.Link>
            </NavDropdown>

            <NavDropdown title="Loan Tools" id="collasible-nav-dropdown">
              <Nav.Link
                className="dropdown-item"
                rel="noopener"
                target="_blank"
                href="https://www.sunwestmortgage.com/loan-calculators-mortgage-calculators"
              >
                Calculators
              </Nav.Link>
              <Nav.Link
                className="dropdown-item"
                rel="noopener"
                target="_blank"
                href="https://www.sunwestmortgage.com/loan-programs"
              >
                Loan Products
              </Nav.Link>
              <Nav.Link
                className="dropdown-item"
                href="https://swmc.com/blog/"
                target="_blank"
                rel="noopener"
                style={style}
              >
                Blog
              </Nav.Link>

              <Nav.Link
                className="dropdown-item"
                href="https://sunwestmortgage.myloaninfo.com/loanmanager/glossary/"
                rel="noopener"
                target="_blank"
                style={style}
              >
                Glossary
              </Nav.Link>
              <Nav.Link
                className="dropdown-item"
                href="https://www.sunwestmortgage.com/faq"
                rel="noopener"
                target="_blank"
              >
                FAQ
              </Nav.Link>
              <Nav.Link
                className="dropdown-item"
                href="https://www.sunwestmortgage.com/guides"
                rel="noopener"
                target="_blank"
              >
                Our Mortgage Guides
              </Nav.Link>
              <Nav.Link
                className="dropdown-item"
                href="https://www.sunwestmortgage.com/documents"
                rel="noopener"
                target="_blank"
              >
                Documents Needed
              </Nav.Link>
              <Nav.Link
                className="dropdown-item"
                href="https://www.sunwestmortgage.com/renovation-loan"
                rel="noopener"
                target="_blank"
              >
                Home Style Renovation
              </Nav.Link>
              <Nav.Link
                className="dropdown-item"
                href="https://www.sunwestmortgage.com/loan-servicing-access"
                rel="noopener"
                target="_blank"
              >
                Loan Servicing Access
              </Nav.Link>
              <Nav.Link
                className="dropdown-item"
                href="https://askangel.ai/"
                target="_blank"
                rel="noopener"
                style={style}
              >
                AskAngel.Ai
              </Nav.Link>
            </NavDropdown>

            <NavDropdown title="Client Login" id="collasible-nav-dropdown">
              <Nav.Link
                className="dropdown-item"
                href="https://seemyloanstatus.com/ReverseMortgage/jsp/extSeeMyLoanStatusLogin.jsf?loginByUsingView=CMOL"
                target="_blank"
                rel="noopener"
                style={style}
              >
                Check My Loan Progress
              </Nav.Link>
              <Nav.Link
                className="dropdown-item"
                href="https://sunwestmortgage.myloaninfo.com/loanmanager/login/"
                target="_blank"
                rel="noopener"
                style={style}
              >
                Make a Payment
              </Nav.Link>
              <Nav.Link
                className="dropdown-item"
                href="https://www.sunwestmortgage.com/loss-mitigation"
                target="_blank"
                rel="noopener"
              >
                Servicing Portal
              </Nav.Link>
              {/* <Nav.Link
                className="dropdown-item"
                href="https://www.sunwestmortgage.com/covid19-relief"
                target="_blank"
                rel="noopener"
              >
                COVID-19 Relief
              </Nav.Link> */}
              <Nav.Link
                className="dropdown-item"
                href="https://www.sunwestmortgage.com/property-insurance"
                target="_blank"
                rel="noopener"
              >
                Property Insurance
              </Nav.Link>
              <Nav.Link
                className="dropdown-item"
                href="https://www.sunwestmortgage.com/sunsoft-login"
                target="_blank"
                rel="noopener"
              >
                Sunsoft Login
              </Nav.Link>
              <Nav.Link
                className="dropdown-item"
                href="https://www.sunwestmortgage.com/login"
                target="_blank"
                rel="noopener"
              >
                Partner Login
              </Nav.Link>
            </NavDropdown>

            <a
              className="nav-link"
              href="https://www.sunwestmortgage.com/loan-officer-search"
              target="_blank"
              rel="noopener"
            >
              Loan Officer Search
            </a>

            <Button
              className={Classes.homepageButtonApply}
              variant="primary"
              size="md"
              onClick={handleShowLeadFormModal}
            >
              Apply Now
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <LeadFormModal
        leadSrc="INHNPORT-070"
        loanPurpKeyword=""
        formHeading="Apply Now"
        showLeadFormModal={showLeadForm}
        onHide={handleCloseLeadFormModal}
        statesDomain="sunwestmortgage.com"
      />
    </NavWrapper>
  );
};

export default NavBar;
