/* eslint-disable react/jsx-no-target-blank */
import React, { Suspense } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import { lazy } from '@loadable/component';
import styles from '../Body/body.module.css';

const Image = lazy(() => import('react-bootstrap/Image'));

const Stylewrapper = styled.div`
  .nav-pills .nav-a {
    background-color: transparent;
    color: black;
    border-radius: 20px;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-a {
    color: #fff;
    background-color: #083f88;
    margin: 0px;
    border-radius: 20px;
  }

  a {
    text-decoration: none;
  }
`;

const scrollVisible = () => {
    window.scrollBy(0, 1);
  };


export default function Steps() {
  return (
    <Container fluid style={{ marginBottom: '0px', marginTop: '20px' }}>
        <Row
        className={styles.headerRow}
        style={{ justifyContent: 'center' }}
        >
        <div className={styles.header_border}> </div>
        <h1 className={styles.whysunwesth2}>With You Every Step</h1>
        </Row>

        <Tab.Container
        id="stepTabs"
        defaultActiveKey="first"
        transition={false}
        >
        <Row>
            <Col sm={12}>
            <p style={{ textAlign: 'center' }}>
                Whether you're a first-time buyer or a home loan financing
                pro, we've got you covered. We create a plan specific for you
                and will guide you every step of the way.
            </p>
            </Col>

            <Stylewrapper style={{ margin: '0 auto' }}>
            <Nav
                variant="pills"
                style={{ margin: '0 auto', width: 'fit-content' }}
                className={styles.navPills}
            >
                <Nav.Item>
                <Nav.Link eventKey="first" onClick={scrollVisible}>
                    Homebuying
                </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                <Nav.Link eventKey="second" onClick={scrollVisible}>
                    Refinance
                </Nav.Link>
                </Nav.Item>
            </Nav>
            </Stylewrapper>

            <Tab.Content style={{ width: '99%' }}>
            <Tab.Pane eventKey="first">
                <div className={styles.containercircle}>
                <div className={styles.circlewrapper}>
                    <div className={styles.semi}>
                    <ScrollAnimation animateIn="fadeInDown">
                        <Suspense fallback={<div>Loading...</div>}>
                        <div className={styles.full}>
                            <Image
                            src="https://www.sunwestmortgage.com/swmc-images/Homepage/timeline/1.svg"
                            alt="Peoples"
                            />
                        </div>
                        </Suspense>
                    </ScrollAnimation>
                    </div>
                    <div className={styles.typosection}>
                    <p>
                        Find a local loan officer or work with one online and
                        get started with your online application
                    </p>
                    <a
                        href="https://www.sunwestmortgage.com/apply"
                        target="_blank"
                        rel="noopener"
                    >
                        <Button
                        size="sm"
                        variant="primary"
                        className={styles.getStartedButton}
                        >
                        Get Started
                        </Button>
                    </a>
                    </div>
                </div>
                <div className={styles.circlewrapper}>
                    <div className={styles.semi + ' ' + styles.sidedown}>
                    <ScrollAnimation animateIn="zoomIn">
                        <Suspense fallback={<div>Loading...</div>}>
                        <div className={styles.full}>
                            <Image
                            src="https://www.sunwestmortgage.com/swmc-images/Homepage/timeline/2.svg"
                            alt="Circle check mark"
                            />
                        </div>
                        </Suspense>
                    </ScrollAnimation>
                    </div>
                    <div
                    className={
                        styles.typosection + ' ' + styles.sidedowntypo
                    }
                    >
                    <p>
                        Get TRU Approved! With a Thoroughly Reviewed &
                        Underwritten (TRU) loan approval, take the fast lane
                        while removing contingencies from your offer.
                    </p>
                    </div>
                </div>
                <div className={styles.circlewrapper}>
                    <div className={styles.semi}>
                    <ScrollAnimation animateIn="fadeInDown">
                        <Suspense fallback={<div>Loading...</div>}>
                        <div className={styles.full}>
                            <Image
                            src="https://www.sunwestmortgage.com/swmc-images/Homepage/timeline/3.svg"
                            alt="Rates icon inside an home"
                            />
                        </div>
                        </Suspense>
                    </ScrollAnimation>
                    </div>
                    <div className={styles.typosection}>
                    <p>
                        Shop Confidently knowing exactly how much you can
                        afford and make an offer on your dream home.
                    </p>
                    </div>
                </div>
                <div className={styles.circlewrapper}>
                    <div className={styles.semi + ' ' + styles.sidedown}>
                    <ScrollAnimation animateIn="zoomIn">
                        <Suspense fallback={<div>Loading...</div>}>
                        <div className={styles.full}>
                            <Image
                            src="https://www.sunwestmortgage.com/swmc-images/Homepage/timeline/4.svg"
                            alt="Gift inside a home"
                            />
                        </div>
                        </Suspense>
                    </ScrollAnimation>
                    </div>
                    <div
                    className={
                        styles.typosection + ' ' + styles.sidedowntypo
                    }
                    >
                    <p>
                        Finalize & Close. Finish up the documentation,
                        appraisal, and close to get the keys to move in.
                        Celebrate and insert your happy dance here.
                    </p>
                    </div>
                </div>
                </div>
            </Tab.Pane>

            <Tab.Pane eventKey="second">
                <div className={styles.containercircle}>
                <div className={styles.circlewrapper}>
                    <div className={styles.semi}>
                    <ScrollAnimation animateIn="fadeInDown">
                        <Suspense fallback={<div>Loading...</div>}>
                        <div className={styles.full}>
                            <Image
                            src="https://www.sunwestmortgage.com/swmc-images/Homepage/timeline/Refinance1.svg"
                            alt="Blue dots"
                            />
                        </div>
                        </Suspense>
                    </ScrollAnimation>
                    </div>
                    <div className={styles.typosection}>
                    <p>
                        Find a local loan officer or work with one online and
                        get started with your online application
                    </p>
                    <a
                        href="https://www.sunwestmortgage.com/apply"
                        target="_blank"
                        rel="noopener"
                    >
                        <Button
                        size="sm"
                        variant="primary"
                        className={styles.getStartedButton}
                        >
                        Get Started
                        </Button>
                    </a>
                    </div>
                </div>
                <div className={styles.circlewrapper}>
                    <div className={styles.semi + ' ' + styles.sidedown}>
                    <ScrollAnimation animateIn="zoomIn">
                        <Suspense fallback={<div>Loading...</div>}>
                        <div className={styles.full}>
                            <Image
                            src="https://www.sunwestmortgage.com/swmc-images/Homepage/timeline/Refinance2.svg"
                            alt="Manify and a star"
                            />
                        </div>
                        </Suspense>
                    </ScrollAnimation>
                    </div>
                    <div
                    className={
                        styles.typosection + ' ' + styles.sidedowntypo
                    }
                    >
                    <p>
                        Options galore! From lowering your payment, shortening
                        your loan term, consolidating debt, turning your
                        home's equity into cash, or removing private mortgage
                        insurance, we've got options (and guidance too)!
                    </p>
                    </div>
                </div>
                <div className={styles.circlewrapper}>
                    <div className={styles.semi}>
                    <ScrollAnimation animateIn="fadeInDown">
                        <Suspense fallback={<div>Loading...</div>}>
                        <div className={styles.full}>
                            <Image
                            src="https://www.sunwestmortgage.com/swmc-images/Homepage/timeline/Refinance3.svg"
                            alt="Check mark"
                            />
                        </div>
                        </Suspense>
                    </ScrollAnimation>
                    </div>
                    <div className={styles.typosection}>
                    <p>
                        Submit all documentation & appraisal, and finalize the
                        loan
                    </p>
                    </div>
                </div>

                <div className={styles.circlewrapper}>
                    <div className={styles.semi + ' ' + styles.sidedown}>
                    <ScrollAnimation animateIn="zoomIn">
                        <Suspense fallback={<div>Loading...</div>}>
                        <div className={styles.full}>
                            <Image
                            src="https://www.sunwestmortgage.com/swmc-images/Homepage/timeline/Refinance4.svg"
                            alt="Home and money"
                            />
                        </div>
                        </Suspense>
                    </ScrollAnimation>
                    </div>
                    <div
                    className={
                        styles.typosection + ' ' + styles.sidedowntypo
                    }
                    >
                    <p>
                        Close knowing you made a great choice got a great
                        deal.
                    </p>
                    </div>
                </div>
                </div>
            </Tab.Pane>
            </Tab.Content>
        </Row>
        </Tab.Container>
    </Container>
  )
}
